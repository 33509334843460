import React, { Component, } from 'react'
import { withRouter, } from 'react-router-dom'
import Box from 'components/Box'
import Header1 from 'typography/Header1'
import HeroImage from 'images/hero.png'
import PrimaryButton from 'components/PrimaryButton'
import Section from 'components/Section'

class ErrorBoundaryView extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  goBack = () => {
    this.props.history.goBack()
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo)
    this.setState({ hasError: true })
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children
    }

    return (
      <Section className="bg-black-mostly-transparent h-screen min-h-screen">
        <Box
          className="flex flex-col h-full items-center justify-center relative w-full"
          style={{
            backgroundImage: `url(${HeroImage})`,
            backgroundSize: 'cover'
          }}
        >
          <Box className="absolute bg-black-less-transparent h-full left-0 top-0 w-full"/>
          <Box className="flex flex-col items-center justify-center mx-auto max-w-8xl px-6 lg:px-32 py-8 lg:py-24 z-10">
            <Header1
              className="font-medium font-portico-outline leading-tight text-3xl lg:text-6xl text-center text-primary"
              // style={{ 'word-spacing': '30px' }}
            >
              Oops, we broke it!
            </Header1>
            <PrimaryButton className="mt-8 w-max-content z-10" onClick={this.goBack}>
              Go back
            </PrimaryButton>
          </Box>
        </Box>
      </Section>
    )
  }
}

export default withRouter(ErrorBoundaryView)
