import React, { useEffect, useState, } from 'react'
import { makeStyles, } from '@material-ui/core/styles'
import { useHistory, } from 'react-router-dom'
import Box from 'components/Box'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import NavLink from 'components/NavLink'
import Section from 'components/Section'

const useStyles = makeStyles(theme => ({
  iconButton: {
    padding: 0,
    fontSize: '2.25rem',
  },
}))

export default () => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }

    return () => document.body.classList.remove('overflow-hidden')
  }, [open])

  const navigateTo = (path) => {
    setOpen(false)
    history.push(path)
  }

  const toggleOpen = (event) => {
    event.preventDefault()
    setOpen(!open)
  }

  // <NavLink
    // href="/learn"
    // onClick={() => navigateTo('/learn')}
  // >Learn</NavLink>
  return (
    <Section
      className={`
        bg-transparent
        flex
        fixed
        ${open ? 'h-full' : 'h-16'}
        lg:h-16
        items-center
        justify-end
        lg:pl-8
        pr-4
        lg:pr-16
        pt-4
        lg:py-4
        xxl:py-12
        lg:w-full
        ${open ? 'pl-8 w-full' : 'self-end w-max-content'}
        max-w-8xl
        z-50
      `}
    >
      <div className="flex items-center h-full justify-end w-full lg:w-auto z-50">
        <Box
          className={`
            absolute
            bg-black-xiketic
            lg:hidden
            h-screen
            left-0
            top-0
            w-full
            ${open ? '' : 'hidden'}
          `}
        />
        <Box
          className={`
            lg:flex
            lg:flex-row
            ${open ? 'flex-col h-full mt-16 z-50 w-full' : 'hidden'}
          `}
        >
          <NavLink
            href="/#challenges"
            onClick={() => navigateTo('/#challenges')}
          >Challenges</NavLink>
          <NavLink
            href="/#battle-bots"
            onClick={() => navigateTo('/#battle-bots')}
          >Battle Bots</NavLink>
          <NavLink
            href="/#aim-dj"
            onClick={() => navigateTo('/#aim-dj')}
          >Aim DJ</NavLink>
          <NavLink
            href="/#forge"
            onClick={() => navigateTo('/#forge')}
          >The Forge</NavLink>
        </Box>
        <Box
          className={`
            lg:hidden
            leading-none
            self-start
            text-white
            text-xl
          `}
        >
          <IconButton
            aria-label="open drawer"
            className={classes.iconButton}
            color="inherit"
            onClick={toggleOpen}
          >
            {
              open ? (
                <CloseIcon fontSize="inherit"/>
              ) : (
                <MenuIcon fontSize="inherit"/>
              )
            }
          </IconButton>
        </Box>
      </div>
    </Section>
  )
}
