import React from 'react'
import { useHistory, } from 'react-router-dom'
import Box from 'components/Box'
import Header4 from 'typography/Header4'
import Link from 'components/Link'
import Logo from 'components/Logo'
import Section from 'components/Section'
import Text from 'typography/Text'

const FooterLink = ({ target = '_self', ...props }) => (
  <Link
    className="font-nexa-bold mt-2 md:mt-2 hover:opacity-50 text-sm xxl:text-lg text-white"
    target={target}
    {...props}
  />
)

const FooterSection = ({ children, ...props }) => (
  <Box className="flex flex-col md:ml-24 first:ml-0 mt-6 md:mt-0">{children}</Box>
)

const FooterSubheader = ({ children, ...props }) => (
  <Header4 className="font-nexa-bold mb-1 md:mb-2 text-primary xxl:text-xl">
    {children}
  </Header4>
)

export default () => {
  const history = useHistory()

  const visitHome = event => {
    history.push('/')
  }

  return (
    <Section className="flex flex-col pb-8 md:pb-4 xxl:pb-8 pt-4 md:pt-24 px-6 md:px-32">
      <Box className="flex flex-col md:flex-row">
        <Box className="hidden lg:block flex-1 justify-start">
          <Logo className="cursor-pointer h-32 xxl:h-48 w-auto" onClick={visitHome}/>
        </Box>
        <Box className="flex flex-col md:flex-row flex-2 justify-center ml-0">
          <FooterSection>
            <FooterSubheader>Social Media</FooterSubheader>
            <FooterLink
              href="https://discord.com/invite/WUwHA9p"
              target="_blank"
            >Join our Discord</FooterLink>
            <FooterLink
              href="https://www.facebook.com/NetPlayDotLife/?utm_content=aimftw"
              target="_blank"
            >
              Follow us on Facebook
            </FooterLink>
            <FooterLink
              href="https://www.twitch.tv/aimftwgg"
              target="_blank"
            >
              Watch on Twitch
            </FooterLink>
            <FooterLink
              href="https://twitter.com/NetPlayDotLife"
              target="_blank"
            >
              Chat with us on Twitter
            </FooterLink>
            <FooterLink
              href="https://www.youtube.com/playlist?list=PLjceIFuX0j0gWYRU2IwFTDylC8Fazhqna"
              target="_blank"
            >
              Explore on YouTube
            </FooterLink>
          </FooterSection>
          <FooterSection>
            <FooterSubheader>Legal</FooterSubheader>
            <FooterLink href="/privacy-policy">Privacy Policy</FooterLink>
            <FooterLink href="/terms-of-service">Terms of Service</FooterLink>
            <FooterLink href="/eula">EULA</FooterLink>
            <FooterLink
              href="http://bit.ly/2U9m5hq"
              target="_blank"
            >
              Manual
            </FooterLink>
          </FooterSection>
          <FooterSection>
            <FooterSubheader>About</FooterSubheader>
            <FooterLink href="/mission">Mission</FooterLink>
            <FooterLink href="/team">Team</FooterLink>
            <FooterLink href="mailto:support@aimftw.gg" target="_blank">
              Support
            </FooterLink>
            <FooterLink
              href="http://www.netplay.gg"
              target="_blank"
            >NetPlay</FooterLink>
            <FooterLink
              href="https://angel.co/company/netplay-esports/jobs"
              target="_blank"
            >Careers</FooterLink>
          </FooterSection>
        </Box>
        <Box className="flex-1 hidden lg:block"/>
      </Box>
      <Text className="font-nexa-light mt-4 md:mt-8 text-left md:text-center text-xs md:text-sm xxl:text-base text-white">
        Copyright NetPlay 2021.<br className="md:hidden"/> All Rights Reserved.
      </Text>
    </Section>
  )
}
