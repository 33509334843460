import React from 'react'
import { Helmet, } from 'react-helmet'
import AimDJ from 'images/aim-dj.png'
import BattleBots from 'images/battle-bots-ads.png'
import Box from 'components/Box'
import ChallengeIsland from 'images/challenge-island.png'
import Computer from 'images/gamer-computer.jpg'
import Forge from 'images/forge.png'
import Header1 from 'typography/Header1'
import Header2 from 'typography/Header2'
import Header3 from 'typography/Header3'
import HeroImage from 'images/hero.png'
import Image from 'components/Image'
import Logo from 'components/Logo'
import Premium from 'images/premium.png'
import PrimaryButton from 'components/PrimaryButton'
import Section from 'components/Section'
import Statistics from 'images/statistics.png'
import Text from 'typography/Text'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'

const SECTIONS = [
  {
    background: 'black-xiketic',
    details: [
      'Compete in a selection of difficult challenges to see how your aim compares to the competition.',
      'Match your skills against theirs and vie for 1st place on the leaderboards.',
      'Receive breakdowns of your performance to see where you need to improve.',
      'Earn rewards to enhance your gameplay.',
    ],
    id: 'challenges',
    image: ChallengeIsland,
    listColor: 'white',
    reverse: false,
    text: 'white',
    title: 'Challenge Yourself',
  },
  {
    background: 'black',
    details: [
      'Practice your aim in a game-like setting with computer-controlled agents.',
      'Mercilous foes, these agents hunt you down in a brutal game of hide and seek.',
      'As you improve, the agents adapt to your skill level pushing your skills to the limit.',
    ],
    id: 'battle-bots',
    image: BattleBots,
    listColor: 'white',
    reverse: true,
    text: 'white',
    title: 'Battle AI',
  },
  {
    background: 'white-100',
    cover: true,
    details: [
      'Prefer a more mellow pace?',
      'Relax while training to the beat of your favorite songs.',
      'Play along to one of our songs or add your own!',
      'Coast along on 1 of 3 maps.',
      'Set the difficulty to match your mood.',
    ],
    id: 'aim-dj',
    image: AimDJ,
    listColor: 'black',
    reverse: false,
    text: 'white',
    title: 'Music Meets Aim',
  },
  {
    background: 'black-xiketic',
    details: [
      'Train the way you want so that you can play the way you dream™.',
      'Configure targets, monsters, agents and more.',
      'Choose exactly how you want the game to challenge you and watch your skills improve!',
    ],
    id: 'forge',
    image: Forge,
    listColor: 'white',
    onClick: () => window.open('https://store.steampowered.com/app/1372790/Aim_FTW__The_Forge/', '_blank'),
    reverse: true,
    text: 'white',
    title: 'Forge Your Skills',
  },
  {
    background: 'white-100',
    cover: true,
    details: [
      'Immediately unlock characters and monsters.',
      'Earn access to many future characters, monsters and more when they release.',
      'Support future development of awesome features for Aim FTW.',
    ],
    id: 'founder',
    image: Premium,
    listColor: 'black',
    onClick: () => window.open('https://store.steampowered.com/app/1372791/Aim_FTW__Founders_Pack/', '_blank'),
    reverse: false,
    text: 'white',
    title: "Become a Founder",
  },
  {
    background: 'black',
    details: [
      'Detailed data reveals your strengths and weaknesses.',
      'Earn insights and tips into how you can improve.',
      'Measure your progress over time to track improvement.',
      'See how you compare to other players.',
    ],
    id: 'stats',
    image: Statistics,
    listColor: 'white',
    reverse: true,
    text: 'white',
    title: "Statistical Analysis",
  },
]

const LandingContainer = ({ children, className, ...props }) => (
  <Box
    className={`mx-auto max-w-8xl xxl:max-w-10xl px-6 lg:px-32 py-10 lg:py-24 ${className}`}
  >{children}</Box>
)

const LandingSubheader = ({ children, className, cover, ...props }) => (
  <Header3
    className={`
      font-medium
      font-portico
      leading-none
      text-center
      text-primary
      ${cover ? 'z-10' : ''}
      ${className}`
    }
  >{children}</Header3>
)

const LandingSection = ({
  background, cover, details, id, image, listColor, onClick = () => {}, reverse, text, title, ...props
}) => (
  <Section
    className={`bg-${background} text-${text} ${cover ? 'relative' : ''}`}
    id={id}
  >
    <Box
      className={`${cover ? 'absolute' : 'hidden'} bg-black-mostly-transparent h-full left-0 top-0 w-full`}
    />
    <LandingContainer
      className={`
        flex
        flex-col
        items-stretch
        sm:items-center
        lg:items-stretch
        xxl:py-32
        ${reverse ? 'lg:flex-row-reverse': 'lg:flex-row'}
        justify-around
        xxl:justify-between
        ${cover ? 'z-10' : ''}
      `}
    >
      <LandingSubheader
        className={`lg:hidden text-3xl sm:text-4xl`}
        cover={cover}
      >
        {title}
      </LandingSubheader>
      <Image
        className={`
          cursor-pointer
          lg:h-80
          xxl:h-112
          mt-8
          lg:mt-0
          rounded
          w-full
          sm:w-4/5
          lg:w-auto
          ${cover ? 'z-10' : ''}
        `}
        onClick={onClick}
        src={image}
      />
      <Box className="flex flex-col justify-evenly mt-6 lg:mt-0 max-w-md xxl:max-w-xl">
        <LandingSubheader
          className={`hidden lg:block text-3xl lg:text-4xl xxl:text-5xl`}
          cover={cover}
        >
          {title}
        </LandingSubheader>
        <Box className="flex flex-col justify-center">
          {
            details.map((detail, detailIndex) => {
              return (
                <Box
                  className={`flex items-center mt-4 xxl:mt-6 ${cover ? 'z-10' : ''}`}
                  key={`detail-${detailIndex}`}
                >
                  <i
                    className={`
                      bg-${listColor}
                      h-2
                      min-h-2
                      mr-4
                      rounded-full
                      w-2
                      min-w-2
                    `}
                  />
                  <Text className={`font-sans leading-tight xxl:leading-normal text-base lg:text-lg xxl:text-2xl text-${listColor}`}>
                    {detail}
                  </Text>
                </Box>
              )
            })
          }
        </Box>
      </Box>
    </LandingContainer>
  </Section>
)

export default () => {
  const googleAnalytics = useGoogleAnalytics()
  googleAnalytics()

  const visitSteam = (event) => {
    window.open(
      'https://store.steampowered.com/app/1111300/Aim_FTW/',
      '_blank'
    )
  }
  // <iframe
    // src="https://store.steampowered.com/widget/1111300/?t=Play%20now%20on%20Steam"
    // frameborder="0"
    // title="Steam Widget"
    // width="484"
    // height="190">
  // </iframe>
  return (
    <Section className="flex flex-col h-full min-h-screen w-full min-w-screen">
      <Helmet>
        <title>Aim FTW, the Video Game That Makes You a Better Gamer</title>
        <link rel="canonical" href="https://www.aimftw.gg/"/>
        <meta property="og:description" content="Aim FTW, the video game that makes you a better gamer. A world-class aim trainer designed to help you improve faster than you thought possible. This game will push you to your limits. Not for the faint of heart."/>
        <meta property="og:image" content={HeroImage}/>
        <meta property="og:title" content="Aim FTW, the Video Game That Makes You a Better Gamer"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.aimftw.gg/"/>
        <meta name="author" content="NetPlay"/>
        <meta name="description" content="Aim FTW, the video game that makes you a better gamer. A world-class aim trainer designed to help you improve faster than you thought possible." />
        <meta name="keywords" content="accuracy,aimbot,call of duty,click test,cs:go,esports,fortnite,game training,gamer,gamer training,gaming,keyboard,mechanics,mouse,mouse accuracy,mouse aim,mouse aim training,mouse exercises,mouse click test,mouse practice,mouse training games,overwatch,pubg,reflex training,training,valorant"/>
      </Helmet>
      <Box
        className="flex flex-col h-screen items-center justify-center relative w-full"
        style={{
          backgroundImage: `url(${HeroImage})`,
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Box className="absolute bg-black-transparent xxl:bg-black-less-transparent h-full left-0 top-0 w-full"/>
        <LandingContainer className="flex flex-col items-center text-center">
          <Logo className="hidden lg:block h-64 w-auto z-10"/>
          <Header1
            className={`
              font-medium
              xxl:font-semibold
              font-portico-outline
              leading-tight
              lg:leading-32
              xxl:mt-10
              text-6xl
              sm:text-9xl
              lg:text-12xl
              xxl:text-15xl
              text-primary
              z-10
            `}
          >
            Aim FTW
          </Header1>
          <Header2 className="font-nexa-bold mt-4 sm:mt-2 lg:mt-6 xxl:mt-12 text-lg sm:text-2xl lg:text-4xl xxl:text-5xl text-white z-10">
            The Video Game That Makes<br className="lg:hidden"/> You a Better Gamer
          </Header2>
          <PrimaryButton className="mt-8 lg:mt-6 xxl:mt-10 z-10" onClick={visitSteam}>
            Play now on Steam
          </PrimaryButton>
        </LandingContainer>
      </Box>
      {
        SECTIONS.map((section, sectionIndex) => (
          <LandingSection
            key={`section-${sectionIndex}`}
            onClick={section.onClick || visitSteam}
            {...section}
          />
        ))
      }
      <Section className="bg-black-xiketic text-white" id="progress">
        <Box
          className="flex flex-col h-112 xxl:h-160 items-center justify-center relative w-full"
          style={{
            backgroundImage: `url(${Computer})`,
            backgroundSize: 'cover',
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <Box className="absolute bg-black-transparent h-full left-0 top-0 w-full"/>
          <LandingContainer className="flex flex-col items-center justify-center">
            <Header3
              className={`
                font-medium
                font-nexa-bold
                text-3xl
                lg:text-4xl
                xxl:text-5xl
                text-center
                text-white
                z-10
              `}
            >
              Don't let the competition<br className="lg:hidden"/> get a <br className="sm:hidden"/> head start!
            </Header3>
            <PrimaryButton className="mt-8 lg:mt-12 xxl:mt-16 w-max-content z-10" onClick={visitSteam}>
              Play now on Steam
            </PrimaryButton>
          </LandingContainer>
        </Box>
      </Section>
    </Section>
  )
}
