import React from 'react'
import { Helmet, } from 'react-helmet'
import Box from 'components/Box'
import Header1 from 'typography/Header1'
import HeroImage from 'images/hero.png'
import Image from 'components/Image'
import Link from 'components/Link'
import Section from 'components/Section'
import TeamImage from 'images/team.png'
import Text from 'typography/Text'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'

export default () => {
  const googleAnalytics = useGoogleAnalytics()
  googleAnalytics()

  return (
    <Section>
      <Helmet>
        <title>Aim FTW Team</title>
        <link rel="canonical" href="https://www.aimftw.gg/team"/>
        <meta property="og:description" content="Aim FTW, the video game that makes you a better gamer. A world-class aim trainer designed to help you improve faster than you thought possible. This game will push you to your limits. Not for the faint of heart."/>
        <meta property="og:image" content={HeroImage}/>
        <meta property="og:title" content="Aim FTW Team"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.aimftw.gg/team"/>
        <meta name="author" content="NetPlay"/>
        <meta name="description" content="Aim FTW's Team. Aim FTW is the video game that makes you a better gamer. A world-class aim trainer designed to help you improve faster than you thought possible." />
        <meta name="keywords" content="accuracy,aimbot,call of duty,click test,cs:go,esports,fortnite,game training,gamer,gamer training,gaming,keyboard,mechanics,mouse,mouse accuracy,mouse aim,mouse aim training,mouse exercises,mouse click test,mouse practice,mouse training games,overwatch,pubg,reflex training,training,valorant"/>
      </Helmet>
      <Box
        className="flex flex-col h-auto min-h-screen items-center justify-center relative w-full"
        style={{
          backgroundImage: `url(${HeroImage})`,
          backgroundSize: 'cover'
        }}
      >
        <Box className="absolute bg-black-less-transparent lg:bg-black-even-less-transparent h-full left-0 top-0 w-full"/>
        <Box className="mx-auto max-w-8xl xxl:max-w-10xl mt-8 sm:mt-0 px-6 sm:px-32 xxl:px-16 py-8 lg:py-24 z-10">
          <Header1
            className="font-medium font-portico-outline leading-tight sm:mt-8 lg:mt-10 text-3xl sm:text-5xl lg:text-9xl xxl:text-12xl text-center text-primary"
            // style={{ 'word-spacing': '30px' }}
          >
            AIM FTW Team
          </Header1>
          <Box className="flex flex-col xxl:flex-row items-center mt-8 xxl:mt-16">
            <Box className="hidden lg:block relative rounded max-w-4xl xxl:max-w-none w-auto">
              <Box className="absolute bg-black-mostly-transparent h-full left-0 top-0 w-full"/>
              <Image className="xxl:h-112 xxl:min-h-112 xxl:max-w-none xxl:min-w-192" src={TeamImage}/>
            </Box>
            <Box className="lg:bg-black-less-transparent xxl:bg-black flex flex-col font-sans xxl:ml-12 lg:mt-8 px-8 lg:py-6 rounded w-full xxl:text-xl max-w-4xl xxl:max-w-2xl">
              <Text className="">Aim FTW is a creation of the NetPlay team. We are a group of gamers who want to help other gamers have more fun playing video games. With Aim FTW, we hope to help other FPS players improve their aim thus landing more headshots, scoring more kills and winning more games.</Text>
              <Text className="mt-6"><Link className="text-primary" href="http://www.netplay.gg" target="_blank">NetPlay, Inc.</Link> was officially founded in late 2016, but it is the culmination of many more years of hard work, late nights and extended gaming sessions. The video game and esports industries are constantly evolving and we are tireless in our pursuit to deliver the best products possible.</Text>
              <Text className="mt-6">We are a globally distributed team with a developing presence around the world led by our founder, wInevitable. Interested in joining the hunt? Have a suggestion you would like to share? Drop us a line at <Link href="mailto:support@aimftw.gg" className="text-primary">support@aimftw.gg</Link>.</Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Section>
  )
}
