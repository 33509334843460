import React from 'react'
import { Helmet, } from 'react-helmet'
import HeroImage from 'images/hero.png'
import Section from 'components/Section'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'

export default () => {
  const googleAnalytics = useGoogleAnalytics()
  googleAnalytics()

  return (
    <Section>
      <Helmet>
        <title>Aim FTW Blog</title>
        <link rel="canonical" href="https://www.aimftw.gg/learn"/>
        <meta property="og:description" content="Aim FTW, the video game that makes you a better gamer. A world-class aim trainer designed to help you improve faster than you thought possible. This game will push you to your limits. Not for the faint of heart."/>
        <meta property="og:image" content={HeroImage}/>
        <meta property="og:title" content="Aim FTW Blog"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.aimftw.gg/learn"/>
        <meta name="author" content="NetPlay"/>
        <meta name="description" content="Aim FTW's Blog. Aim FTW is the video game that makes you a better gamer. A world-class aim trainer designed to help you improve faster than you thought possible." />
        <meta name="keywords" content="accuracy,aimbot,call of duty,click test,cs:go,esports,fortnite,game training,gamer,gamer training,gaming,keyboard,mechanics,mouse,mouse accuracy,mouse aim,mouse aim training,mouse exercises,mouse click test,mouse practice,mouse training games,overwatch,pubg,reflex training,training,valorant"/>
      </Helmet>
    </Section>
  )
}
