import React from 'react'
import { Helmet, } from 'react-helmet'
import Box from 'components/Box'
import Header1 from 'typography/Header1'
import Header6 from 'typography/Header6'
import HeroImage from 'images/hero.png'
import Section from 'components/Section'
import Text from 'typography/Text'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'

const PARAGRAPHS = [
  {
    details: [
      'If you require any more information or have any questions about our privacy policy, please feel free to contact us by email at support@aimftw.gg.',
      'At www.aimftw.gg we consider the privacy of our visitors to be extremely important. This privacy policy document describes in detail the types of personal information we collect and record at www.aimftw.gg and how we use it.',
    ],
  },
  {
    details: [
      "Like many other Web sites, www.aimftw.gg makes use of log files. These files merely log visitors to the site - usually a standard procedure for hosting companies and a part of hosting services's analytics. The information inside the log files includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date/time stamp, referring/exit pages, and possibly the number of clicks.",
      "This information is used to analyze trends, administer the site, track user's movement around the site, and gather demographic information. IP addresses, and other such information are not linked to any information that is personally identifiable.",
    ],
    title: 'Log Files',
  },
  {
    details: [
      "www.aimftw.gg uses cookies to store information about visitors' preferences, to record user-specific information on which pages the site visitor accesses or visits, and to personalize or customize our web page content based upon visitors' browser type or other information that the visitor sends via their browser.",
    ],
    title: 'Cookies and Web Beacons',
  },
  {
    details: [
      '→ Google, as a third party vendor, uses cookies to serve ads on www.aimftw.gg.',
      "→ Google's use of the DART cookie enables it to serve ads to our site's visitors based upon their visit to www.aimftw.gg and other sites on the Internet.",
      '→ Users may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy at the following URL www.google.com/privacy_ads',
    ],
    title: 'DoubleClick DART Cookie',
  },
  {
    details: [
      'We believe it is important to provide added protection for children online. We encourage parents and guardians to spend time online with their children to observe, participate in and/or monitor and guide their online activity.',
      'www.aimftw.gg does not knowingly collect any personally identifiable information from children under the age of 13. If a parent or guardian believes that www.aimftw.gg has in its database the personally-identifiable information of a child under the age of 13, please contact us immediately (using the contact in the first paragraph) and we will use our best efforts to promptly remove such information from our records.',
    ],
    title: "Children's Information",
  },
  {
    details: [
      'This privacy policy applies only to our online activities and is valid for visitors to our website and regarding information shared and/or collected there. This policy does not apply to any information collected offline or via channels other than this website.',
    ],
    title: 'Online Privacy Policy Only',
  },
  {
    details: [
      'By using our website, you hereby consent to our privacy policy and agree to its terms.',
    ],
    title: 'Consent',
  },
  {
    details: [
      'This Privacy Policy was last updated on: Sunday, July 26th, 2020.',
      'Should we update, amend or make any changes to our privacy policy, those changes will be posted here.'
    ],
    title: 'Update',
  },
]

export default () => {
  const googleAnalytics = useGoogleAnalytics()
  googleAnalytics()

  return (
    <Section className="bg-black-mostly-transparent">
      <Helmet>
        <title>Aim FTW Privacy Policy</title>
        <link rel="canonical" href="https://www.aimftw.gg/privacy-policy"/>
        <meta property="og:description" content="Aim FTW, the video game that makes you a better gamer. A world-class aim trainer designed to help you improve faster than you thought possible. This game will push you to your limits. Not for the faint of heart."/>
        <meta property="og:image" content={HeroImage}/>
        <meta property="og:title" content="Aim FTW Privacy Policy"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.aimftw.gg/privacy-policy"/>
        <meta name="author" content="NetPlay"/>
        <meta name="description" content="Aim FTW's Privacy Policy. Aim FTW is the video game that makes you a better gamer. A world-class aim trainer designed to help you improve faster than you thought possible." />
        <meta name="keywords" content="accuracy,aimbot,call of duty,click test,cs:go,esports,fortnite,game training,gamer,gamer training,gaming,keyboard,mechanics,mouse,mouse accuracy,mouse aim,mouse aim training,mouse exercises,mouse click test,mouse practice,mouse training games,overwatch,pubg,reflex training,training,valorant"/>
      </Helmet>
      <Box
        className="flex flex-col h-auto items-center justify-center relative w-full"
        style={{
          backgroundImage: `url(${HeroImage})`,
          backgroundSize: 'cover'
        }}
      >
        <Box className="absolute bg-black-less-transparent xxl:bg-black-even-less-transparent h-full left-0 top-0 w-full"/>
        <Box className="mx-auto max-w-8xl xxl:max-w-9xl px-6 lg:px-32 py-8 lg:py-24 z-10">
          <Header1
            className="font-medium font-portico-outline leading-tight mt-10 text-3xl sm:text-5xl lg:text-6xl xxl:text-9xl text-center text-primary"
            // style={{ 'word-spacing': '30px' }}
          >
            AIM FTW<br className="lg:hidden xxl:block"/> Privacy Policy
          </Header1>
          <Box className="flex flex-col font-sans mt-6 px-0 sm:px-24 lg:px-48 text-center">
            {
              PARAGRAPHS.map((paragraph, paragraphIndex) => {
                return (
                  <Box className="mt-6 xxl:mt-16" key={`paragraph-${paragraphIndex}`}>
                    <Header6 className="mb-4 text-center xxl:text-2xl">{paragraph.title}</Header6>
                    {
                      paragraph.details.map((detail, detailIndex) => {
                        return (
                          <Text
                            className="block mt-6 xxl:text-xl"
                            key={`detail-${paragraphIndex}-${detailIndex}`}
                          >{detail}</Text>
                        )
                      })
                    }
                  </Box>
                )
              })
            }
          </Box>
        </Box>
      </Box>
    </Section>
  )
}
