import React from 'react'
import { Helmet, } from 'react-helmet'
import Box from 'components/Box'
import Header1 from 'typography/Header1'
import HeroImage from 'images/hero.png'
import Link from 'components/Link'
import Logo from 'components/Logo'
import Section from 'components/Section'
import Text from 'typography/Text'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'

export default () => {
  const googleAnalytics = useGoogleAnalytics()
  googleAnalytics()

  return (
    <Section>
      <Helmet>
        <title>Aim FTW Mission</title>
        <link rel="canonical" href="https://www.aimftw.gg/mission"/>
        <meta property="og:description" content="Aim FTW, the video game that makes you a better gamer. A world-class aim trainer designed to help you improve faster than you thought possible. This game will push you to your limits. Not for the faint of heart."/>
        <meta property="og:image" content={HeroImage}/>
        <meta property="og:title" content="Aim FTW Mission"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.aimftw.gg/mission"/>
        <meta name="author" content="NetPlay"/>
        <meta name="description" content="Aim FTW's Mission Statement. Aim FTW is the video game that makes you a better gamer. A world-class aim trainer designed to help you improve faster than you thought possible." />
        <meta name="keywords" content="accuracy,aimbot,call of duty,click test,cs:go,esports,fortnite,game training,gamer,gamer training,gaming,keyboard,mechanics,mouse,mouse accuracy,mouse aim,mouse aim training,mouse exercises,mouse click test,mouse practice,mouse training games,overwatch,pubg,reflex training,training,valorant"/>
      </Helmet>
      <Box
        className="flex flex-col h-auto min-h-screen items-center justify-center relative w-full"
        style={{
          backgroundImage: `url(${HeroImage})`,
          backgroundSize: 'cover'
        }}
      >
        <Box className="absolute bg-black-less-transparent lg:bg-black-even-less-transparent h-full left-0 top-0 w-full"/>
        <Box className="mx-auto max-w-8xl xxl:max-w-10xl mt-8 sm:mt-0 px-6 sm:px-32 xxl:px-0 py-8 lg:py-24 z-10">
          <Header1
            className="font-medium font-portico-outline leading-tight sm:mt-8 lg:mt-10 xxl:mt-0 text-3xl sm:text-5xl lg:text-9xl xxl:text-12xl text-center text-primary"
            // style={{ 'word-spacing': '30px' }}
          >
            AIM FTW's Mission
          </Header1>
          <Box className="flex font-sans items-center mt-8 lg:mt-16 xxl:mt-32">
            <Box className="lg:bg-black-less-transparent xxl:bg-black flex flex-1 flex-col lg:mr-12 px-8 lg:py-6 rounded xxl:text-xl max-w-2xl">
              <Text className="">
                &emsp;Aim FTW exists to help all dedicated first-person shooter (FPS) players on their path to greatness. Our goal is to help you help yourself in progressing your gameplay and skill within any FPS. By using science, machine learning and powerful algorithms we can adapt the training to your skill level and help push you out of your comfort zone so that you improve faster than by playing regular video games.
              </Text>
              <Text className="mt-6">
                &emsp;Aim FTW is a product of <Link className="text-primary" href="http://www.netplay.gg" target="_blank">NetPlay, Inc.</Link> and the output of hard work from our team of talented artisans and gamers who love video games and esports. Aim FTW first launched in August, 2019, but is the derivative of years of research, gaming and passion.
              </Text>
              <Text className="mt-6">
                &emsp;The future is bright for Aim FTW with many new features currently in development and yet more to come after that. Download the game today from <Link className="text-primary" href="https://steamcommunity.com/app/1111300" target="_blank">Steam</Link> or sign up for our <Link href="mailto:support@aimftw.gg?subject=Mailing%20List" className="text-primary">mailing list</Link> to stay connected and learn about future releases.
              </Text>
            </Box>
            <Logo className="hidden lg:block h-96 xxl:h-112 max-h-96 xxl:max-h-112 min-h-96 xxl:min-h-112 rounded w-auto"/>
          </Box>
        </Box>
      </Box>
    </Section>
  )
}
