import React from 'react'
import ReactDOM from 'react-dom'
import './tailwind.output.css'
import './index.css'
import App from './App'
import ReactGA from 'react-ga'
import * as Sentry from '@sentry/react'
import * as serviceWorker from './serviceWorker'

if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
  ReactGA.pageview(window.location.pathname + window.location.search)
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_API_KEY
})

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
