import React from 'react'
import Footer from 'components/Footer'
import Navigation from 'components/Navigation'
import Section from 'components/Section'

export default ({ children, ...props }) => {
  return (
    <Section className="bg-black flex flex-col min-h-screen min-w-screen">
      <Navigation/>
      <Section className="">{children}</Section>
      <Footer/>
    </Section>
  )
}
