import React from 'react'
import Button from 'components/Button'

export default ({ children, className, ...props }) => (
  <Button
    className={`
      bg-primary
      hover:bg-primary-100
      font-portico
      px-6
      lg:px-8
      xxl:px-10
      py-4
      lg:py-6
      xxl:py-8
      rounded
      text-sm
      lg:text-lg
      xxl:text-2xl
      ${className}
    `}
    {...props}
  >
    {children}
  </Button>
)
