import React from 'react'
import { Helmet, } from 'react-helmet'
import Box from 'components/Box'
import Header1 from 'typography/Header1'
import Header6 from 'typography/Header6'
import HeroImage from 'images/hero.png'
import Section from 'components/Section'
import Text from 'typography/Text'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'

const PARAGRAPHS = [
  {
    details: [
      'Last updated: July 31, 2020',
      `Please read this End-User License Agreement ("Agreement") carefully before clicking the "I Agree" button, downloading or using Aim FTW ("Application").`,
      `By clicking the "I Agree" button, downloading or using the Application, you are agreeing to be bound by the terms and conditions of this Agreement.`,
      `This Agreement is a legal agreement between you (either an individual or a single entity) and NetPlay, Inc. and it governs your use of the Application made available to you by NetPlay, Inc..`,
      `If you do not agree to the terms of this Agreement, do not click on the "I Agree" button and do not download or use the Application.`,
      `The Application is licensed, not sold, to you by NetPlay, Inc. for use strictly in accordance with the terms of this Agreement.`,
    ],
    title: 'End-User License Agreement ("Agreement")',
  },
  {
    details: [
      'NetPlay, Inc. grants you a revocable, non-exclusive, non-transferable, limited license to download, install and use the Application strictly in accordance with the terms of this Agreement.',
    ],
    title: 'License',
  },
  {
    details: [
      'You agree not to, and you will not permit others to:',
      '• license, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the Application or make the Application available to any third party.',
      "• copy or use the Application for any purpose other than as permitted under the above section 'License'.",
      '• modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part of the Application.',
      '• remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of NetPlay, Inc. or its affiliates, partners, suppliers or the licensors of the Application.',
    ],
    title: 'Restrictions',
  },
  {
    details: [
      'The Application, including without limitation all copyrights, patents, trademarks, trade secrets and other intellectual property rights are, and shall remain, the sole and exclusive property of NetPlay, Inc..',
    ],
    title: 'Intellectual Property',
  },
  {
    details: [
      'Any feedback, comments, ideas, improvements or suggestions (collectively, "Suggestions") provided by you to NetPlay, Inc. with respect to the Application shall remain the sole and exclusive property of NetPlay, Inc..',
      'NetPlay, Inc. shall be free to use, copy, modify, publish, or redistribute the Suggestions for any purpose and in any way without any credit or any compensation to you.',
    ],
    title: 'Your Suggestions',
  },
  {
    details: [
      'NetPlay, Inc. reserves the right to modify, suspend or discontinue, temporarily or permanently, the Application or any service to which it connects, with or without notice and without liability to you.',
    ],
    title: 'Modifications to Application',
  },
  {
    details: [
      'NetPlay, Inc. may from time to time provide enhancements or improvements to the features/functionality of the Application, which may include patches, bug fixes, updates, upgrades and other modifications ("Updates").',
      'Updates may modify or delete certain features and/or functionalities of the Application. You agree that NetPlay, Inc. has no obligation to (i) provide any Updates, or (ii) continue to provide or enable any particular features and/or functionalities of the Application to you.',
      'You further agree that all Updates will be (i) deemed to constitute an integral part of the Application, and (ii) subject to the terms and conditions of this Agreement.',
    ],
    title: 'Updates to Application',
  },
  {
    details: [
      'The Application may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services ("Third-Party Services").',
      'You acknowledge and agree that NetPlay, Inc. shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. NetPlay, Inc. does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services.',
      "Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties' terms and conditions.",
    ],
    title: 'Third-Party Services',
  },
  {
    details: [
      'NetPlay, Inc. collects, stores, maintains, and shares information about you in accordance with its Privacy Policy, which is available at https://www.aimftw.gg/privacy-policy. By accepting this Agreement, you acknowledge that you hereby agree and consent to the terms and conditions of our Privacy Policy.',
    ],
    title: 'Privacy Policy',
  },
  {
    details: [
      'This Agreement shall remain in effect until terminated by you or NetPlay, Inc..',
      'NetPlay, Inc. may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.',
      'This Agreement will terminate immediately, without prior notice from NetPlay, Inc., in the event that you fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting the Application and all copies thereof from your mobile device or from your computer.',
      'Upon termination of this Agreement, you shall cease all use of the Application and delete all copies of the Application from your mobile device or from your computer.',
      "Termination of this Agreement will not limit any of NetPlay, Inc.'s rights or remedies at law or in equity in case of breach by you (during the term of this Agreement) of any of your obligations under the present Agreement.",
    ],
    title: 'Term and Termination',
  },
  {
    details: [
      "You agree to indemnify and hold NetPlay, Inc. and its parents, subsidiaries, affiliates, officers, employees, agents, partners and licensors (if any) harmless from any claim or demand, including reasonable attorneys' fees, due to or arising out of your: (a) use of the Application; (b) violation of this Agreement or any law or regulation; or (c) violation of any right of a third party.",
    ],
    title: 'Indemnification',
  },
  {
    details: [
      `The Application is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, NetPlay, Inc., on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Application, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, NetPlay, Inc. provides no warranty or undertaking, and makes no representation of any kind that the Application will meet your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.`,
      "Without limiting the foregoing, neither NetPlay, Inc. nor any NetPlay, Inc.'s provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Application, or the information, content, and materials or products included thereon; (ii) that the Application will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Application; or (iv) that the Application, its servers, the content, or e-mails sent from or on behalf of NetPlay, Inc. are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.",
      'Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations on the applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.',
    ],
    title: 'No Warranties',
  },
  {
    details: [
      'Notwithstanding any damages that you might incur, the entire liability of NetPlay, Inc. and any of its suppliers under any provision of this Agreement and your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by you for the Application.',
      'To the maximum extent permitted by applicable law, in no event shall NetPlay, Inc. or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, for loss of data or other information, for business interruption, for personal injury, for loss of privacy arising out of or in any way related to the use of or inability to use the Application, third-party software and/or third-party hardware used with the Application, or otherwise in connection with any provision of this Agreement), even if NetPlay, Inc. or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.',
      'Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you.',
    ],
    title: 'Limitation of Liability',
  },
  {
    details: [
      'If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.',
    ],
    title: 'Severability',
  },
  {
    details: [
      "Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Agreement shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute waiver of any subsequent breach.",
    ],
    title: 'Waiver',
  },
  {
    details: [
      "NetPlay, Inc. reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.",
      'By continuing to access or use our Application after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Application.',
    ],
    title: 'Amendments to this Agreement',
  },
  {
    details: [
      'The laws of New York, United States, excluding its conflicts of law rules, shall govern this Agreement and your use of the Application. Your use of the Application may also be subject to other local, state, national, or international laws.',
    ],
    title: 'Governing Law',
  },
  {
    details: [
      'If you have any questions about this Agreement, please contact us by email at support@aimftw.gg.',
    ],
    title: 'Contact Information',
  },
  {
    details: [
      'The Agreement constitutes the entire agreement between you and NetPlay, Inc. regarding your use of the Application and supersedes all prior and contemporaneous written or oral agreements between you and NetPlay, Inc..',
      "You may be subject to additional terms and conditions that apply when you use or purchase other NetPlay, Inc.'s services, which NetPlay, Inc. will provide to you at the time of such use or purchase.",
    ],
    title: 'Entire Agreement',
  },
]

export default () => {
  const googleAnalytics = useGoogleAnalytics()
  googleAnalytics()

  return (
    <Section className="bg-black">
      <Helmet>
        <title>Aim FTW EULA</title>
        <link rel="canonical" href="https://www.aimftw.gg/eula"/>
        <meta property="og:description" content="Aim FTW, the video game that makes you a better gamer. A world-class aim trainer designed to help you improve faster than you thought possible. This game will push you to your limits. Not for the faint of heart."/>
        <meta property="og:image" content={HeroImage}/>
        <meta property="og:title" content="Aim FTW End-User License Agreement"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.aimftw.gg/eula"/>
        <meta name="author" content="NetPlay"/>
        <meta name="description" content="Aim FTW's End-User License Agreement. Aim FTW is the video game that makes you a better gamer. A world-class aim trainer designed to help you improve faster than you thought possible." />
        <meta name="keywords" content="accuracy,aimbot,call of duty,click test,cs:go,esports,fortnite,game training,gamer,gamer training,gaming,keyboard,mechanics,mouse,mouse accuracy,mouse aim,mouse aim training,mouse exercises,mouse click test,mouse practice,mouse training games,overwatch,pubg,reflex training,training,valorant"/>
      </Helmet>
      <Box
        className="flex flex-col h-auto items-center justify-center relative w-full"
        style={{
          backgroundImage: `url(${HeroImage})`,
          backgroundSize: 'cover'
        }}
      >
        <Box className="absolute bg-black-less-transparent xxl:bg-black-even-less-transparent h-full left-0 top-0 w-full"/>
        <Box className="mx-auto max-w-8xl xxl:max-w-9xl px-6 lg:px-32 py-8 lg:py-24 z-10">
          <Header1
            className="font-medium font-portico-outline leading-tight mt-10 text-3xl sm:text-5xl lg:text-6xl xxl:text-9xl text-center text-primary"
            // style={{ 'word-spacing': '30px' }}
          >
            AIM FTW<br className="lg:hidden xxl:block"/> End User License Agreement
          </Header1>
          <Box className="flex flex-col lg:mt-6 px-0 sm:px-12 lg:px-24">
            {
              PARAGRAPHS.map((paragraph, paragraphIndex) => {
                return (
                  <Box className="mt-6 xxl:mt-16" key={`paragraph-${paragraphIndex}`}>
                    <Header6 className="mb-4 text-center text-lg xxl:text-2xl">{paragraph.title}</Header6>
                    {
                      paragraph.details.map((detail, detailIndex) => {
                        return (
                          <Text
                            className="block mt-6 xxl:text-xl"
                            key={`detail-${paragraphIndex}-${detailIndex}`}
                          >{detail}</Text>
                        )
                      })
                    }
                  </Box>
                )
              })
            }
          </Box>
        </Box>
      </Box>
    </Section>
  )
}
