import React from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom'
import MissionView from 'views/MissionView'
import ArticleView from 'views/ArticleView'
import ErrorBoundaryView from 'views/ErrorBoundaryView'
import EULAView from 'views/EULAView'
import LandingView from 'views/LandingView'
import Layout from 'layouts/Layout'
import PrivacyPolicyView from 'views/PrivacyPolicyView'
import TeamView from 'views/TeamView'
import TermsOfServiceView from 'views/TermsOfServiceView'

export default () => (
  <Router>
    <Layout>
      <ErrorBoundaryView>
        <Switch>
          <Route exact path="/" component={LandingView}/>
          <Route path="/eula" component={EULAView}/>
          <Route path="/learn" component={ArticleView}/>
          <Route path="/mission" component={MissionView}/>
          <Route path="/team" component={TeamView}/>
          <Route path="/terms-of-service" component={TermsOfServiceView}/>
          <Route path="/privacy-policy" component={PrivacyPolicyView}/>
          <Route component={LandingView}/>
        </Switch>
      </ErrorBoundaryView>
    </Layout>
  </Router>
)
